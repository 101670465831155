import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import _ from "lodash";

import Main from "@/pages/Main/Main";
import UserSearch from "@/pages/User/UserSearch";
import CalcHistSearch from "@/pages/User/CalcHistSearch";
import IimsHistSearch from "@/pages/User/IimsHistSearch";
import InflowHistSearch from "@/pages/User/InflowHistSearch";
import InsurerSearch from "@/pages/User/InsurerSearch";
import EventLottery from "@/pages/User/EventLottery";
import ContentHistory from "@/pages/User/ContentHistory"
import UserGroup from "@/pages/User/UserGroup"
import SignUpRequest from "@/pages/User/SignUpRequest"
import BlackList from "@/pages/User/BlackList"
import ExcMember from "@/pages/User/ExcMember"
// import DuesList from "@/pages/User/DuesApplicationList"
import PaymentManage from "@/pages/Settlement/PaymentManage";

import Settlement from "@/pages/Settlement/Settlement";
import RencarSettlement from "@/pages/Settlement/RencarSettlement";
import RealtimeContract from "@/pages/Settlement/RealtimeContract";
import SalesByOfficer from "@/pages/Settlement/SalesByOfficer";

import e404 from '@/components/Error/404';
import SearchCode from "@/pages/Manage/Code/SearchCode";
import CreateCode from "@/pages/Manage/Code/CreateCode";
import MasterCode from "@/pages/Manage/Code/MasterCode";
import DetailCode from "@/pages/Manage/Code/DetailCode";
import Orginfo from "@/pages/Operation/Orginfo";
import SalesList from "@/pages/Settlement/SalesList";
import CarSalesList from "@/pages/Settlement/CarSalesList";
// import UserStatSearch from "@/pages/Stat/UserStatSearch";
import CalcStatSearch from "@/pages/Stat/CalcStatSearch";
import PerformanceStat from "@/pages/Stat/PerformanceStat";
import OperationStat from  "@/pages/Stat/OperationStat";
import CollectionSearch from "@/pages/Collection/CollectionSearch";
import ExcelExtractHist from "@/pages/Stat/ExcelExtractHist";
import PartnerSearch from "@/pages/User/PartnerSearch";
import AdminRegister from "@/pages/Manage/Admin/AdminRegister";
import AdminManage from "@/pages/Manage/Admin/AdminManage";
import SettlementDetail from "@/pages/Settlement/SettlementDetail";
import OpenBanking from "@/pages/Manage/Banking/OpenBanking";
import ContentsFileReg from "@/pages/Manage/ContentsFile/ContentsFileReg";
import NeedsInfoFile from "@/pages/Manage/NeedsInfo/NeedsInfoFile";
import BoardMain from "@/pages/Manage/Board/BoardMain";
import ProductRecommendation from "@/pages/Product/ProductRecommendation";
import ProductRecommendationAccountStatus from "@/pages/Product/ProductRecommendationAccountStatus";
import ProductGuide from "@/pages/Guide/ProductGuide";
import ProductManagement from "@/pages/ProductManagement/ProductManagement";
import ProductMappingManagement from "@/pages/Product/ProductMappingManagement";
import ApplicationConditionManagement from "@/pages/Product/ApplicationConditionManagement";
import ProductInformationManagement from "@/pages/Product/ProductInformationManagement";
import ProductPlanManagement from "@/pages/Product/ProductPlanManagement";
import MainCvrStatus from "@/pages/Product/MainCvrStatus";
import CreCvrMappingStatus from "@/pages/Product/CreCvrMappingStatus";
import InsCvrStatus from "@/pages/Product/InsCvrStatus";
import InsCvrMappingBasicData from "@/pages/Product/InsCvrMappingBasicData";
import InsCvrMappingStatus from "@/pages/Product/InsCvrMappingStatus";
import ProductCompareManagement from "@/pages/Product/ProductCompareManagement"
import ProductCondPrem from "@/pages/Product/ProductCondPrem"
import ProductRecommendUsageOperationStatus from "@/pages/Product/ProductRecommendUsageOperationStatus"
import ProductRecommendUsageStatus from "@/pages/Product/ProductRecommendUsageStatus"
import ProductDataSync from "@/pages/Product/ProductDataSync";
import ProductSpCvrAmt  from "@/pages/Product/ProductSpCvrAmt";
import ProductRecommendBoard  from "@/pages/Product/ProductRecommendBoard";
import InsuranceFee from "@/pages/Insurance/InsuranceFee";
import EncryDecryption from "@/pages/Manage/EncryDecryption/EncryDecryption";
import TransChartToText from "@/pages/Manage/TransChartToText/TransChartToText";

import ImgPdf from "@/pages/Manage/Test/ImgPdf";
import IdPhoto from "@/pages/Manage/Test/IdPhoto";
import ProgramPrivilege from "@/pages/Manage/ProgramPrivilege/ProgramPrivilege";
import UserProgramPrivilege from "@/pages/Manage/UserProgramPrivilege/UserProgramPrivilege";

import CarExpirInfo from "@/pages/Operation/CarExpirInfo";
import CarExpirInfoJob from "@/pages/Operation/CarExpirInfoJob";

import MessageCustomerManagement from "@/pages/Message/MessageCustomerManagement"

import ReceptionStatus from "@/pages/ReceptionStatus/ReceptionStatus";
import ProductGuidelines from "@/pages/ProductGuidelines/ProductGuidelines";
import LinkedSecurityManagement from "@/pages/Linked/LinkedSecurityManagement";
import DesignSupport from "@/pages/Support/DesignSupport";
import DesignSupportStatus from "@/pages/Support/DesignSupportStatus";
import CampaignManagement from "@/pages/Manage/Campaign/CampaignManagement";
import InsTermInfoMgmt from "@/pages/Manage/InsTermInfo/InsTermInfoMgmt";
import CheckIncarRealTime from "@/pages/Manage/CheckIncarRealTime/CheckIncarRealTime";
import InsRealtimeContract from "@/pages/Settlement/InsRealtimeContract";
import SettlementEarly from "@/pages/Settlement/SettlementEarly"
import InsAiChatManagement from "@/pages/Manage/InsAiChatManagement/InsAiChatManagement";
import NewPaymentManage from "@/pages/Settlement/NewPaymentManage";
import SettlementInsSummary from "@/pages/Settlement/SettlementInsSummary";
import ProductRecommendWorkStatus from "@/pages/Product/ProductRecommendWorkStatus";
import NoneSettleMember from "@/pages/Operation/NoneSettleMember";
import SettlementPayback from "@/pages/Settlement/SettlementPayback";
import OBIncarMember from "@/pages/User/OBIncarMember";
import AssignmentManage from "@/pages/Manage/CS/AssignmentManage";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "Main",
            component: Main,
            children: [
                {
                    path: "/",
                    name: "MainPage",
                    component: e404
                },
                // path : "/stat/~"
                {
                    path: "/stat/calc",
                    name: "CalcStatSearch",
                    component: CalcStatSearch
                },
                {
                    path: "/stat/performance",
                    name: "PerformanceStat",
                    component: PerformanceStat
                },
                {
                    path: "/stat/operation",
                    name: "OperationStat",
                    component: OperationStat
                },
                {
                    path: "/collection/status",
                    name: "CollectionSearch",
                    component: CollectionSearch
                },
                {
                    path: "/stat/excelExtractHist",
                    name: "ExcelExtractHist",
                    component: ExcelExtractHist
                },
                {
                    path: "/message/customer/management",
                    name: "MessageCustomerManagement",
                    component: MessageCustomerManagement
                },
                // path : "/info/~"
                {
                    path: "/info/user",
                    name: "UserSearch",
                    component: UserSearch
                },
                {
                    path: "/info/calc",
                    name: "CalcHistSearch",
                    component: CalcHistSearch,
                    props: true,
                },
                {
                    path: "/info/iims",
                    name: "IimsHistSearch",
                    component: IimsHistSearch,
                    props: true,
                },
                {
                    path: "/info/inflow",
                    name: "InflowHistSearch",
                    component: InflowHistSearch,
                    props: true,
                },
                {
                    path: "/info/ins",
                    name: "InsurerSearch",
                    component: InsurerSearch
                },
                {
                    path: "/info/partner",
                    name: "PartnerSearch",
                    component: PartnerSearch
                },
                {
                    path: "/info/batch",
                    name: "CarExpirInfo",
                    component: CarExpirInfo
                },
                {
                    path: "/info/job",
                    name: "CarExpirInfoJob",
                    component: CarExpirInfoJob
                },
                {
                    path: "/info/event",
                    name: "EventLottery",
                    component: EventLottery
                },
                {
                    path: "/info/content",
                    name: "ContentHistory",
                    component: ContentHistory
                },
                {
                    path: "/info/user/group",
                    name: "UserGroup",
                    component: UserGroup
                },
                {
                    path: "/info/user/signupRequest",
                    name: "SignUpRequest",
                    component: SignUpRequest
                },
                {
                    path: "/info/user/blackList",
                    name: "BlackList",
                    component: BlackList
                },
                {
                    path: "/info/user/excMember",
                    name: "ExcMember",
                    component: ExcMember
                },
                // {
                //     path: "/info/dues/list",
                //     name: "DuesList",
                //     component: DuesList
                // },
                // path : "/settle/~"
                {
                    path: "/rencarSettle/check",
                    name: "RencarSettlement",
                    component: RencarSettlement
                },
                {
                    path: "/settle/check",
                    name: "Settlement",
                    component: Settlement
                },
                {
                    path: "/settle/detail",
                    name: "SettlementDetail",
                    component: SettlementDetail
                },
                {
                    path: "/settle/payment",
                    name: "PaymentManage",
                    component: PaymentManage
                },
                {
                    path: "/settle/realtimeContract",
                    name: "RealtimeContract",
                    component: RealtimeContract
                },
                {
                    path: "/settle/salesByOfficer",
                    name: "SalesByOfficer",
                    component: SalesByOfficer
                },
                {
                    path: "/manage/code",
                    name: "SearchCode",
                    component: SearchCode
                },
                {
                    path: "/manage/code/master",
                    name: "MasterCodeInfo",
                    component: MasterCode,
                    props: true,
                },
                {
                    path: "/manage/code/detail",
                    name: "DetailCodeInfo",
                    component: DetailCode,
                    props: true,
                },
                {
                    path: "/manage/code/create",
                    name: "CreateCode",
                    component: CreateCode
                },
                {
                    path: "/manage/admin",
                    name: "AdminRegister",
                    component: AdminRegister
                },
                {
                    path: "/manage/permission",
                    name: "AdminManage",
                    component: AdminManage
                },
                {
                    path: "/settle/saleslist",
                    name: "SalesList",
                    component: SalesList
                },
                {
                    path: "/settle/carsaleslist",
                    name: "CarSalesList",
                    component: CarSalesList
                },
                {
                    path: "/product/recommendation",
                    name: "ProductRecommendation",
                    component: ProductRecommendation
                },
                {
                    path: "/product/recommendation/accountStatus",
                    name: "ProductRecommendationAccountStatus",
                    component: ProductRecommendationAccountStatus
                },
                {
                    path: "/product/recommend/usage/operationStat",
                    name: "ProductRecommendUsageOperationStatus",
                    component: ProductRecommendUsageOperationStatus
                },
                {
                    path: "/product/recommend/usage/status",
                    name: "ProductRecommendUsageStatus",
                    component: ProductRecommendUsageStatus
                },
                {
                    path: "/product/guide",
                    name: "ProductGuide",
                    component: ProductGuide
                },
                {
                    path: "/product/management",
                    name: "ProductManagement",
                    component: ProductManagement
                },
                {
                    path: "/manage/encryDecry",
                    name: "EncryDecryption",
                    component: EncryDecryption
                },
                {
                    path: "/manage/transChartToText",
                    name: "TransChartToText",
                    component: TransChartToText
                },
                {
                    path: "/product/appCond/management",
                    name: "ApplicationConditionManagement",
                    component: ApplicationConditionManagement
                },
                {
                    path: "/product/information/management",
                    name: "ProductInformationManagement",
                    component: ProductInformationManagement
                },
                {
                    path: "/product/plan/management",
                    name: "ProductPlanManagement",
                    component: ProductPlanManagement
                },
                {
                    path: "/product/mainCvr/status",
                    name: "MainCvrStatus",
                    component: MainCvrStatus
                },
                {
                    path: "/product/creCvr/mapping/status",
                    name: "CreCvrMappingStatus",
                    component: CreCvrMappingStatus
                },
                {
                    path: "/product/insCvr/status",
                    name: "InsCvrStatus",
                    component: InsCvrStatus
                },
                {
                    path: "/product/insCvr/mapping/basicData",
                    name: "InsCvrMappingBasicData",
                    component: InsCvrMappingBasicData
                },
                {
                    path: "/product/insCvr/mapping/status",
                    name: "InsCvrMappingStatus",
                    component: InsCvrMappingStatus
                },
                {
                    path: "/product/compare/management",
                    name: "ProductCompareManagement",
                    component: ProductCompareManagement
                },
                {
                    path: "/product/condPrem",
                    name: "ProductCondPrem",
                    component: ProductCondPrem
                },
                {
                    path: "/product/dataSync",
                    name: "ProductDataSync",
                    component: ProductDataSync
                },
                {
                    path: "/product/spCvrAmt",
                    name: "ProductSpCvrAmt",
                    component: ProductSpCvrAmt
                },
                {
                    path: "/product/board",
                    name: "ProductRecommendBoard",
                    component: ProductRecommendBoard
                },
                {
                    path: "/info/orginfo",
                    name: "Orginfo",
                    component: Orginfo
                },
                {
                    path: "/manage/openBanking",
                    name: "OpenBanking",
                    component: OpenBanking
                },
                {
                    path: "/manage/contentsFileReg",
                    name: "ContentsFileReg",
                    component: ContentsFileReg
                },
                {
                    path: "/manage/needsInfoFile",
                    name: "NeedsInfoFile",
                    component: NeedsInfoFile
                },
                {
                    path: "/manage/board",
                    name: "BoardMain",
                    component: BoardMain
                },
                {
                    path: "/manage/insTermInfo",
                    name: "InsTermInfoMgmt",
                    component: InsTermInfoMgmt
                },
                {
                    path: "/manage/checkIncarRealTime",
                    name: "CheckIncarRealTime",
                    component: CheckIncarRealTime
                },
                {
                    path: "/manage/programPrivilege",
                    name: "ProgramPrivilege",
                    component: ProgramPrivilege
                },
                {
                    path: "/manage/userProgramPrivilege",
                    name: "UserProgramPrivilege",
                    component: UserProgramPrivilege
                },
                {
                    path: "/reception/status",
                    name: "ReceptionStatus",
                    component: ReceptionStatus
                },
                {
                    path: "/product/guidelines",
                    name: "ProductGuidelines",
                    component: ProductGuidelines
                },
                {
                    path: "/linked/security/management",
                    name: "LinkedSecurityManagement",
                    component: LinkedSecurityManagement
                },
                {
                    path: "/product/mapping/management",
                    name: "ProductMappingManagement",
                    component: ProductMappingManagement
                },
                {
                    path: "/insurance/fee",
                    name: "InsuranceFee",
                    component: InsuranceFee
                },
                {
                    path: "/design/support",
                    name: "DesignSupport",
                    component: DesignSupport
                },
                {
                    path: "/design/support/status",
                    name: "DesignSupportStatus",
                    component: DesignSupportStatus
                },
                {
                    path: "/campaign/management",
                    name: "CampaignManagement",
                    component: CampaignManagement
                },
                {
                    path: "/insRealtime/contract",
                    name: "InsRealtimeContract",
                    component: InsRealtimeContract
                },
                {
                    path: "/settle/early",
                    name: "SettlementEarly",
                    component: SettlementEarly
                },
                {
                    path: "/manage/insAiChat",
                    name: "InsAiChatManagement",
                    component: InsAiChatManagement
                },
                {
                    path: "/settle/payment/new",
                    name: "NewPaymentManage",
                    component: NewPaymentManage
                },
                {
                    path: "/settle/ins/summary",
                    name: "SettlementInsSummary",
                    component: SettlementInsSummary
                },
                {
                    path: "/product/work/status",
                    name: "ProductRecommendWorkStatus",
                    component: ProductRecommendWorkStatus
                },
                {
                    path: "/none/settle",
                    name: "NoneSettleMember",
                    component: NoneSettleMember
                },
                {
                    path: "/test/imgPdf",
                    name: "ImgPdf",
                    component: ImgPdf
                },
                {
                    path: "/test/IdPhoto",
                    name: "IdPhoto",
                    component: IdPhoto
                },
                {
                    path: "/settle/payback",
                    name: "SettlementPayback",
                    component: SettlementPayback
                },
                {
                    path: "/info/user/obIncarMember",
                    name: "OBIncarMember",
                    component: OBIncarMember
                },
                {
                    path: "/cs/manage/assignment",
                    name: "AssignmentManage",
                    component: AssignmentManage
                },
                {
                    path: "*",
                    name: "Error",
                    component: e404
                }
            ]
        },

    ]
})

// 권한이 없는 페이지 접속 시 처리
router.beforeEach(function (to, from, next) {
    if (!store.getters['login/memberId'])
    {
        next();
    }
    else
    {
        if (!_.isEmpty(to.name) &&
            !(
                to.name === 'Main'
                || to.name === 'MainPage'
                || to.name === 'PerformanceStat'    // ADMIN - 유입현황
                || to.name === 'ProductCondPrem'    // MANAGER - 상품추천 점검자 메뉴
                || to.name === 'Error'
            )
        )
        {
            const prvNameList = store.getters['login/personalMenuNameList'];
            const isChildPage = prvNameList.some(el => to.path.includes(el));

            if (isChildPage)
            {
                store.dispatch('login/setPrePage', to.name);
                next();
            }
            else
            {
                alert("해당 페이지에 권한이 없습니다.");
                next({ name: store.getters["login/prePage"] });
            }
        }
        else
        {
            next();
        }
    }
});

export default router
