<template>
  <search-page-layout title="순차배정 관리">
    <template v-slot:table>
      <assignment-manage-table
        :data="data"
        :loading="loading"
        @rowData="getRowData"
        @saveData="saveData"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue";
import {axiosInstance} from "@/util/AxiosModule";
import AssignmentManageTable from "@/pages/Manage/CS/components/AssignmentManageTable.vue";

export default {
  name: "AssignmentManage",
  components: {AssignmentManageTable, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false,
      rowData: {}
    }
  },
  created() {
    this.getSeqAssignmentList();
  },
  methods: {
    getRowData(item) {
      this.rowData = item;
    },
    async getSeqAssignmentList() {
      this.loading = true;
      try {
        const { data } = await axiosInstance.get("/admin/operation/seqAssignmentList");
        if (data.length < 1) {
          this.data = [];
          this.loading = false;
        } else {
          this.data = data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    saveData() {
      this.getSeqAssignmentList();
    }
  }
}
</script>