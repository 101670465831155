<template>
  <v-flex class="ma-2">
    <v-data-table
      :headers="getHeader(headers)"
      :items="data"
      :loading="loading"
      :items-per-page="-1"
      fixed-header
      @click:row="selectRowData"
    >
      <template v-slot:item.passYn="{ value }">
        <span :class="{ 'text-red': value === 'Y' }">{{ conversionToData(value) }}</span>
      </template>
    </v-data-table>
    <div class="py-3 d-flex justify-end">
      <v-btn class="green draken-3 white--text" @click="showModify">등록</v-btn>
    </div>
    <assignment-manage-modify
      :is-show="isShowModify"
      :rowData="rowData"
      :type="type"
      :passYnList="passYnList"
      @save="save"
      @close="isShowModify = false"
    />
  </v-flex>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {ASSIGNMENT_MANAGE_TABLE} from "@/util/TableHeaders";
import AssignmentManageModify from "@/pages/Manage/CS/components/AssignmentManageModify";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props: ["data", "loading"],
  mixins: [TableMixin],
  components: {AssignmentManageModify},
  data() {
    return {
      headers: ASSIGNMENT_MANAGE_TABLE,
      rowData: {},
      isShowModify: false,
      type: "",
      passYnList: [
        { passYnNm: "제외", passYn: "Y" },
        { passYnNm: "대상", passYn: "N" }
      ],
    }
  },
  methods: {
    selectRowData(item) {
      this.isShowModify = true;
      this.type = "U";
      this.searchMemberInfo(item);
    },
    async searchMemberInfo(item) {
      try {
        const { data } = await axiosInstance.get("/admin/operation/seqAssignmentDetail", {
          params: {
            memberId: item.memberId
          }
        });
        if (data.length < 1) {
          this.rowData = {};
        } else {
          this.rowData = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    showModify() {
      this.isShowModify = true;
      this.type = "C";
    },
    save() {
      this.isShowModify = false;
      this.$emit("saveData");
    },
    conversionToData(value) {
      const name = this.passYnList.find(item => item.passYn === value);
      return name ? name.passYnNm : null;
    }
  }
}
</script>

<style scoped>
.text-red {
  color: red;
}
</style>