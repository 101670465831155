<template>
  <v-overlay
    v-model="isShow"
    :light="true" :dark="false"
  >
    <div style="position: absolute; z-index: 999; display: flex; justify-content: flex-end; width: 100%; padding: 10px 20px;">
      <span @click="closePopup" style="font-size: 20px; font-weight: 700; cursor: pointer;">X</span>
    </div>
    <custom-layout title="캠페인정보 등록" subtitle="캠페인정보를 등록하는 팝업입니다.">
      <v-card width="600" height="650">
        <v-row style="display: flex; justify-content: center;">
          <v-col cols="7" class="content-container">
            <span class="content-title">캠페인명</span>
            <v-select
              v-model="campaignCd"
              :items="campaignCdList"
              item-text="campaignCdNm"
              item-value="campaignCd"
              hide-details outlined dense
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">등록회차</span>
            <v-text-field
                v-model="regSeq"
                hide-details outlined dense
            />
            <v-btn
                class="search-button"
                @click="getCampaignInfo"
                style="margin-left: 20px;">
              조회
            </v-btn>
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">설명</span>
            <v-text-field
              v-model="campaignDesc"
              hide-details outlined dense
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">사용시작일</span>
            <v-text-field
                v-model="useFrDt"
                hide-details outlined dense
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">사용종료일</span>
            <v-text-field
                v-model="useToDt"
                hide-details outlined dense
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">배분여부</span>
            <v-select
                v-model="divYn"
                :items="divYnList"
                item-text="divYnNm"
                item-value="divYn"
                hide-details outlined dense
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">정보등록일자</span>
            <v-text-field
                v-model="createDate"
                hide-details outlined dense disabled
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">자료등록일자</span>
            <v-text-field
                v-model="dataRegDate"
                hide-details outlined dense disabled
            />
          </v-col>
          <v-col cols="7" class="content-container">
            <span class="content-title">자료등록건수</span>
            <v-text-field
                v-model="dataRegCnt"
                hide-details outlined dense disabled
            />
          </v-col>
          <v-col cols="7" class="button-container">
            <v-btn @click="resetForm">초기화</v-btn>
            <v-btn @click="campaignInfoSave('delete')">삭제</v-btn>
            <v-btn @click="campaignInfoSave('save')">저장</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </custom-layout>
  </v-overlay>
</template>

<script>
import CustomLayout from "@/layouts/CustomLayout";
import {axiosInstance} from "@/util/AxiosModule";
import store from "@/store";
import _ from "lodash";

export default {
  props: ["isShow"],
  components: {CustomLayout},
  created() {
    this.getCampaignCdList();
  },
  data() {
    return {
      campaignCd: "",
      campaignCdList: [
        { campaignCdNm: "선택", campaignCd: "" }
      ],
      regSeq: 0,
      campaignDesc: "",
      useFrDt: "",
      useToDt: "",
      divYn: "Y",
      divYnList: [
        { divYnNm: "Y", divYn: "Y" },
        { divYnNm: "N", divYn: "N" },
      ],
      createDate: "",
      dataRegDate: "",
      dataRegCnt: 0,
      campaignInfoData: {}
    }
  },
  watch: {
    campaignInfoData(value) {
      if (value !== "") {
        this.campaignDesc = _.cloneDeep(value.campaignDesc);
        this.useFrDt = _.cloneDeep(value.useFrDt);
        this.useToDt = _.cloneDeep(value.useToDt);
        this.divYn = _.cloneDeep(value.divYn);
        this.createDate = _.cloneDeep(value.createDate);
        this.dataRegDate = _.cloneDeep(value.dataRegDate);
        this.dataRegCnt = _.cloneDeep(value.dataRegCnt);
      } else if (value === "") {
        this.divYn = "Y";
        this.dataRegCnt = 0;
      }
    }
  },
  methods: {
    async getCampaignCdList() {
      const { data } = await axiosInstance.get("/public/prdata/code-list", {
        params: {
          cdMasterId: "CAMPAIGN",
          useYn: "Y"
        }
      });
      if (data.length < 1) {
        return false;
      } else {
        for (let i in data) {
          const object = {};
          object.campaignCdNm = data[i].value;
          object.campaignCd = data[i].cd;
          this.campaignCdList.push(object);
        }
      }
    },
    async getCampaignInfo() {
      this.campaignInfoData = {};
      const { data } = await axiosInstance.get("/private/operation/campaignInfo", {
        params: {
          campaignCd: this.campaignCd,
          regSeq: this.regSeq
        }
      })
      if (data.length < 1) {
        this.divYn = "Y";
        this.dataRegCnt = 0;
        alert("등록된 회차가 없습니다.");
      } else {
        data.createDate = data.createDate.replace('T', ' ').substr(0, 11);
        if (data.dataRegDate !== null) {
          data.dataRegDate = data.dataRegDate.replace('T', ' ').substr(0, 11);
        }
        this.campaignInfoData = data;
      }
    },
    campaignInfoSave(type) {
      const campaignInfoDto = {
        campaignCd: this.campaignCd,
        regSeq: Number(this.regSeq),
        campaignDesc: this.campaignDesc,
        useFrDt: this.useFrDt,
        useToDt: this.useToDt,
        divYn: this.divYn,
        regId: store.getters["login/memberId"],
        dataRegCnt: Number(this.dataRegCnt)
      };
      if (type === "save") {
        if (this.checkSaveForm(campaignInfoDto)) {
          if (confirm("정보를 등록하시겠습니까?")) {
            axiosInstance.post("/private/operation/campaignInfoSave", campaignInfoDto, {
              params: {
                actionCd: "C"
              }
            })
                .then(() => {
                  alert("캠페인 등록이 완료되었습니다.");
                  this.resetForm();
                })
                .catch((e) => {
                  console.log(e);
                  alert(e);
                })
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === "delete") {
        if (confirm("정보를 삭제하시겠습니까?")) {
          axiosInstance.post("/private/operation/campaignInfoSave", campaignInfoDto, {
            params: {
              actionCd: "D"
            }
          })
              .then(() => {
                alert("캠페인이 삭제되었습니다.");
                this.resetForm();
              })
              .catch((e) => {
                console.log(e);
              })
        }
      }
    },
    checkSaveForm(data) {
      if (_.isEmpty(data.campaignCd)) {
        alert("캠페인명을 선택해주세요");
        return false;
      } else if (data.regSeq === 0) {
        alert("등록회차를 입력해주세요.");
        return false;
      } else if (_.isEmpty(data.campaignDesc)) {
        alert("설명을 입력해주세요.");
        return false;
      } else if (_.isEmpty(data.useFrDt)) {
        alert("사용시작일을 입력해주세요.");
        return false;
      } else if (_.isEmpty(data.useToDt)) {
        alert("사용종료일을 입력해주세요.");
        return false;
      } else {
        return true;
      }
    },
    resetForm() {
      this.campaignCd = "";
      this.regSeq = 0;
      this.campaignDesc = "";
      this.useFrDt = "";
      this.useToDt = "";
      this.divYn = "Y";
      this.createDate = "";
      this.dataRegDate = "";
      this.dataRegCnt = 0;
    },
    closePopup() {
      this.resetForm();
      this.$emit("close");
    }
  }
}
</script>

<style scoped>
.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-title {
  width: 100px;
  margin-right: 20px;
  word-break: keep-all;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
}

.search-button.v-btn {
  margin-left: 20px;
  background-color: #c0c0c0;
}
</style>