<template>
  <div>
    <search-bar-layout>
      <template v-slot:contents>
        <search-area :key="tgKey">
          <v-col class="d-flex align-center col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
            <v-col cols="3">조회기간</v-col>
            <v-col cols="9">
              <date-input
                  :start-date="startDate"
                  :end-date="endDate"
                  @updateDate="updateDate"
              />
            </v-col>
          </v-col>
            <field-input title="구 분">
              <v-select
                  v-model="useNm"
                  :items="serviceType"
                  background-color="white"
                  item-text="sname"
                  item-value="svalue"
                  hide-details outlined dense
              />
            </field-input>
        </search-area>
      </template>
      <template v-slot:button>
        <search-btn @click="getDaly" />
      </template>
    </search-bar-layout>
    <v-col cols="12" class="py-0">
      <div class="mb-2">
        <span class="text-h6">목록 (총 {{ dailyData.length }}개)</span>
      </div>
      <v-data-table
          height="50vh"
          :items="dailyData"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="headers"
          @click:row="clickContents"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
      </v-data-table>
      <perf-detail-modal
        :key="tablekey"
        :dialog="dialog"
        :boardRow="boardRow"
        @close="dialog = false"
      />
    </v-col>
  </div>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import SearchArea from "../../../../components/Search/SearchArea";
import DateInput from "@/components/Form/DateInput";
import SearchBtn from "../../../../components/Search/SearchBtn";
import CodeMixin from "@/mixin/CodeMixin";
import DateMixin from "@/mixin/DateMixin";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import PerfDetailModal from "./PerfDetailModal";
import FieldInput from "@/components/Form/FieldInput";
import {mapGetters} from "vuex";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {PerfDetailModal, SearchBtn, SearchArea, SearchBarLayout, DateInput, FieldInput},
  props: ["updateData"],
  data:() => ({
    startDate: "",
    endDate: "",
    loading:false,
    tablekey : 0,
    useNm:'',
    dialog:false,
    tgKey: 0,
    dailyData:[],
    boardRow:[],
    headers: [{text: "메뉴명", value: "menuNm", class: "lime lighten-4" , align: "center", sortable: false},
              {text: "일자별", value: "contRealDate", class: "light-blue lighten-4" , align: "center", sortable: false},
              {text: "누적 사용 건수", value: "totCnt", class: "light-blue lighten-4" , align: "center", sortable: false},
              {text: "인카FA 사용 건", value: "incarCnt", class: "lime lighten-4" , align: "center", sortable: false},],
    serviceType:[],
  }),
  async created() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    if (_.isEmpty(this.updateData)) {
      this.startDate = fromDate;
      this.endDate = toDate;
    } else {
      this.startDate = this.updateData.startDt;
      this.endDate = this.updateData.endDt;
      this.useNm = this.updateData.menuNm;
      await this.getDaly();
    }
    if (!_.isEmpty(this.contMenuList)) {
      this.serviceType = this.contMenuList;
    } else {
      await this.$store.dispatch("code/setContMenuList");
      this.serviceType = this.contMenuList;
      this.serviceType.unshift({sname: "선택", svalue: ""})
    }
  },
  computed: {
    ...mapGetters("code", ["contMenuList"])
  },
  watch: {
    updateData: function(value) {
      this.startDate = value.startDt;
      this.endDate = value.endDt;
      this.useNm = value.menuNm;
      this.getDaly();
      this.tgKey++;
    },
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    async getDaly() {
      if (_.isEmpty(this.useNm)) {
        alert("사용자메뉴명을 선택해주세요.")
        return;
      }

      this.loading = true;

      const params = {
        startDt :  this.startDate.replaceAll('-', ''),
        endDt : this.endDate.replaceAll('-', ''),
        menuNm : this.useNm
      }
      try {
        const { data } = await axiosInstance.get('/admin/operation/perf_menu_daily', {params})
        this.dailyData = data;
        for (let i = 0; i < this.dailyData.length; i++) {
          this.dailyData[i].contRealDate = this.dailyData[i].contRealDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    clickContents(row) {
      this.dialog = true;
      this.menuNmModal = row.menuNm;
      this.standardDt = row.contRealDate;

      this.getContentsAPI(this.standardDt, this.menuNmModal)
    },

    async getContentsAPI(boardDt,boardNm) {
      const params = {
        standardDt: boardDt.replaceAll('-', ''),
        menuNm: boardNm
      }
      let response;
      try {
        response = await axiosInstance.get("/admin/operation/perf_menu_user", {params});
        this.boardRow = response.data;
        for (let i = 0; i < this.boardRow.length; i++) {
          if (!_.isEmpty(this.boardRow[i].age)){
            this.boardRow[i].age = Math.floor(this.boardRow[i].age);
          }
          this.boardRow[i].contRealDate = this.boardRow[i].contRealDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
          if (this.boardRow[i].contentsJoinSeq === 0) {
            this.boardRow[i].contFileTitle = ''
          }
        }
      } catch (e) {
        console.log(e)
      }

      this.tablekey++;
    }
  }
}
</script>