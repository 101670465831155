<template>
  <v-layout class="d-flex justify-center" v-if="isShow">
    <v-card class="pa-3">
      <v-card-title class="text-h5 font-weight-bold d-flex justify-space-between">
        <span>정보 {{ type === "C" ? "등록" : "수정" }}</span>
        <span class="pointer" @click="closePopup">X</span>
      </v-card-title>
      <v-row class="d-flex justify-center">
        <v-col class="d-flex justify-center align-center col-4">
          <v-col cols="4">이름</v-col>
          <v-select
              v-model="modifyData.memberId"
              :items="csMemberList"
              item-text="memberNm"
              item-value="memberId"
              dense hide-details outlined
              background-color="white"
              :disabled="type === 'U'"
          />
        </v-col>
        <v-col class="d-flex justify-center align-center col-5">
          <v-col cols="4">순차배정</v-col>
          <v-select
              v-model="modifyData.passYn"
              :items="passYnList"
              item-text="passYnNm"
              item-value="passYn"
              dense hide-details outlined
              background-color="white"
          />
        </v-col>
      </v-row>
      <div class="mt-3 mx-2">
        <span class="font-weight-bold">
          * 체크채널에 대해서만 본인에게 배정되며 나머지 채널은 순차 배정자에게 배정됩니다.
        </span>
      </div>
      <div class="d-flex justify-center align-center">
        <v-checkbox
            class="pa-2"
            v-for="(item, index) in channelList" :key="index"
            :label="item.value" :value="item.cd"
            v-model="selectChannelList"
        />
      </div>

      <v-card-actions class="d-flex justify-end">
        <v-btn class="blue darken-2 white--text" @click="dataApi(type)" :disabled="disableSaveButton">{{ type === "C" ? "생성" : "수정" }}</v-btn>
        <v-btn class="red darken-2 white--text" @click="dataApi('D')" :disabled="type === 'C'">삭제</v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</template>

<script>
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import store from "@/store";

export default {
  props: ["isShow", "type", "passYnList", "rowData"],
  data() {
    return {
      modifyData: {
        memberId: "",
        passYn: "N",
        channel: "",
        channelList: []
      },
      channelList: [],
      csMemberList: [],
      selectChannelList: []
    }
  },
  created() {
    this.getChannelList();
    this.getCSMemberList();
  },
  computed: {
    disableSaveButton() {
      return _.isEmpty(this.modifyData.memberId);
    }
  },
  watch: {
    rowData(value) {
      if (!_.isEmpty(value)) {
        this.setDefaultData();
        this.modifyData = _.cloneDeep(value);
      }
    },
    modifyData(newValue) {
      if (newValue) {
        this.selectChannelList = [];
        this.modifyData.channelList.map(item => {
          this.selectChannelList.push(item.cd);
        });
      }
    },
    type(newValue) {
      if (newValue) {
        this.setDefaultData();
      }
    }
  },
  methods: {
    setDefaultData() {
      this.modifyData = {
        memberId: "",
        passYn: "Y",
        channel: "",
        channelList: []
      };
    },
    async getChannelList() {
      try {
        const { data } = await axiosInstance.get('/admin/code/all-detail', {
          params: {
            cdMasterId : "CS_CHANNEL",
            type: "KEY"
          }
        });
        if (_.isEmpty(data)) {
          this.channelList = [];
        } else {
          data.map(item => {
            this.channelList.push({
              value: item.value,
              cd: item.cd
            })
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCSMemberList() {
      try {
        const { data } = await axiosInstance.get("/admin/operation/getCsMemberList");
        if (data.length < 1) {
          this.csMemberList = [];
        } else {
          this.csMemberList = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async dataApi(type) {
      const typeMessages = { C: "등록", U: "수정", D: "삭제" };
      const dataMessage = typeMessages[type];
      if (confirm(`정보를 ${ dataMessage }하시겠습니까?`)) {
        try {
          const seqAssignmentDto = this.modifyData;
          seqAssignmentDto.channel =
              this.selectChannelList
                  .map(cd => this.channelList.find(item => item.cd === cd)?.value)
                  .filter(Boolean)
                  .join(",");
          seqAssignmentDto.regId = store.getters["login/memberId"];
          seqAssignmentDto.action = type;
          await axiosInstance.post("/admin/operation/seqAssignment/management", seqAssignmentDto)
              .then(() => {
                alert(`정보 ${dataMessage} 완료`);
                this.setDefaultData();
                this.$emit("save");
          })
        } catch (error) {
          console.error(error);
        }
      }
    },
    closePopup() {
      this.setDefaultData();
      this.$emit("close");
    }
  }
}
</script>